import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import styles from "./Navbar.module.scss";
import medLogo from "images/med-logo.svg";
import { Office } from "types/office";

export interface NavbarProps {
  children?: React.ReactNode;
  className?: string;
  below?: React.ReactNode;
  primaryLink?: React.ReactNode;
  office?: Office;
}

export const Navbar: React.FC<NavbarProps> = ({
  children,
  primaryLink,
  office,
  below,
  className,
}) => {
  const imgUrl = office?.cobrandedLogoUrl || medLogo;

  return (
    <nav>
      <div className={cx(className, styles.bar)}>
        <div
          className={cx(styles.logoContainer, {
            [styles.cobranded]: !!office?.cobrandedLogoUrl,
          })}
        >
          {primaryLink ? (
            primaryLink
          ) : (
            <Link to="/">
              <img src={imgUrl} className={styles.logo} alt="MED Logo" />
            </Link>
          )}
        </div>
        {children}
      </div>
      {below && <div className={styles.below}>{below}</div>}
    </nav>
  );
};
