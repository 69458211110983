import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export type SessionStatus =
  | "logged-in"
  | "logged-out"
  | "expired"
  | "uninitialized";

export type State = {
  serverError: boolean;
  sessionStatus: SessionStatus;
};

const SLICE_NAME = "patientDashboard";

export const initialState: State = {
  serverError: false,
  sessionStatus: "uninitialized",
};

export const patientDashboardSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setServerError: (state, action: PayloadAction<boolean>) => ({
      ...state,
      serverError: action.payload,
    }),
    setSessionStatus: (state, action: PayloadAction<SessionStatus>) => ({
      ...state,
      sessionStatus: action.payload,
    }),
  },
});

export const reducer = patientDashboardSlice.reducer;
export const { setServerError, setSessionStatus } =
  patientDashboardSlice.actions;

export const selectServerError = (state: RootState): boolean =>
  state.patientDashboard.serverError;

export const selectSessionStatus = (state: RootState): SessionStatus =>
  state.patientDashboard.sessionStatus;

export const patientDashboardReducer = patientDashboardSlice.reducer;
