import axios from "axios";
import { Doctor } from "types/doctor";

export interface GetDoctorResponse {
  id: string;
  first_name: string;
  last_name: string;
  headshot_url?: string;
  offers_veh?: boolean;
}

export const getDoctorsForOfficeRequest = async (
  officeId: string
): Promise<Doctor[]> => {
  const { data } = await axios.get<GetDoctorResponse[]>(
    `/api/offices/${officeId}/doctors`
  );

  return data.map((doctor) => ({
    id: doctor.id,
    firstName: doctor.first_name,
    lastName: doctor.last_name,
    headshotUrl: doctor.headshot_url,
    offersVEH: doctor.offers_veh,
  }));
};
