import { Doctor } from "types/doctor";

export const doctorFullName = (
  doctor: Doctor,
  namechangeVEH?: boolean
): string =>
  doctor.firstName && doctor.firstName.includes("Open Network") && namechangeVEH
    ? "Doctor of Optometry"
    : doctor.firstName && doctor.firstName.includes("Open Network")
    ? "Doctor of Optometry"
    : `Dr. ${doctor.firstName} ${doctor.lastName}`;

export const doctorFullNameVEH = (
  doctor: Doctor,
  namechangeVEH: boolean
): string =>
  doctor.firstName && doctor.firstName.includes("Open Network")
    ? namechangeVEH
      ? "Doctor of Optometry"
      : "Doctor of Optometry"
    : `Dr. ${doctor.firstName} ${doctor.lastName}`;
