import React from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { noop } from "lodash";
import styles from "./DoctorSelectionPanelV2.module.scss";
import { DoctorButtonV2 } from "./DoctorButtonV2";
import { DoctorTimeSlotV2 } from "./DoctorTimeSlotV2";
import { AvailabilityForDoctorV2 } from "./AvailabilityForDoctorV2";
import {
  selectFirstAvailableDatesForAllDoctorsInOffice,
  selectFirstAvailablityLoadedForOffice,
} from "features/scheduling/firstAvailabilitySlice";
import { selectNextAvailableDatesForOffice } from "features/scheduling/availabilitySummarySlice";
import {
  selectUpcomingTimeslotsForOfficeByDate,
  selectIsLoaded,
} from "features/scheduling/timeSlotsSlice";
import { Doctor } from "types/doctor";
import { Office } from "types/office";
import { Slot } from "types/slot";
import { RootState } from "app/store";
import { areFirstAvailableApptsByDefaultEnabled } from "featureFlags/areFirstAvailableApptsByDefaultEnabled";

interface DoctorSelectionPanelProps {
  selectedDoctorId?: string;
  doctors: Doctor[];
  selectedOffice: Office;
  forDate: string;
  visible: boolean;
  onSelection: (type: string, value: Slot) => void;
  onSelectNextAvail: (date: string) => void;
  selectedSlot?: undefined | Slot;
  weekCalendar: string[];
  loadOnce: boolean;
  onSetLoadOnce: () => void;
}

// TODO: scrollAmount can be set to 1 and still successfully work on any browser _except_ Safari

const DoctorSlots: React.FC<DoctorSelectionPanelProps> = ({
  doctors,
  selectedDoctorId,
  selectedOffice,
  forDate,
  visible,
  onSelection,
  onSelectNextAvail,
  selectedSlot,
  weekCalendar,
  loadOnce,
  onSetLoadOnce,
}) => {
  const slotsByDoctor = useSelector((state: RootState) =>
    selectUpcomingTimeslotsForOfficeByDate(state, selectedOffice.id)
  );

  const isSlotLoaded = useSelector((state: RootState) => selectIsLoaded(state));
  if (isSlotLoaded && !loadOnce) {
    const doctorsDayStart = doctors.map((doctor) => {
      return Object.keys(slotsByDoctor[doctor.id])[0];
    });

    let daystart = false;
    for (let i = 0; i < weekCalendar.length; i++) {
      if (daystart) {
        break;
      }
      for (let j = 0; j < doctorsDayStart.length; j++) {
        if (daystart) break;
        if (doctorsDayStart[j] === weekCalendar[i]) {
          daystart = true;
          if (daystart) {
            onSelectNextAvail(weekCalendar[i]);
            onSetLoadOnce();
            break;
          }
        }
      }
    }
  }
  const availabilityByDoctor = useSelector((state: RootState) =>
    selectNextAvailableDatesForOffice(
      state,
      selectedOffice.id,
      forDate,
      selectedOffice.timeSlotWeeks
    )
  );

  const firstAvailability = useSelector((state: RootState) =>
    selectFirstAvailableDatesForAllDoctorsInOffice(state, selectedOffice.id)
  );
  const firstAvailabilityLoaded = useSelector((state: RootState) =>
    selectFirstAvailablityLoadedForOffice(state, selectedOffice.id)
  );
  let isAvailability = 0;
  return (
    <div>
      {doctors.map((doctor, i) => {
        let availability;
        if (areFirstAvailableApptsByDefaultEnabled()) {
          availability = firstAvailabilityLoaded
            ? firstAvailability[doctor.id]
            : "loading";
        } else {
          availability = availabilityByDoctor[doctor.id];
        }

        if (
          doctor.offersVEH &&
          doctor.firstName === "Open Network" &&
          !availability
        ) {
          return null;
        }

        if (availability) {
          isAvailability++;
          return (
            <>
              <div
                className={
                  !visible
                    ? i < 4
                      ? styles.doctorTimeSlotContainer
                      : cx(styles.doctorTimeSlotContainer, styles.divHidden)
                    : styles.doctorTimeSlotContainer
                }
              >
                <div className={styles.contentLeft}>
                  <DoctorButtonV2
                    key={`doctor_${i}`}
                    doctor={doctor}
                    selected={selectedDoctorId === doctor.id}
                    loaded={
                      areFirstAvailableApptsByDefaultEnabled()
                        ? firstAvailabilityLoaded
                        : availability !== "loading"
                    }
                    selectedOffice={selectedOffice}
                    availabilityInfo={
                      <AvailabilityForDoctorV2
                        key={`doctor_avail_${i}`}
                        doctor={doctor}
                        office={selectedOffice}
                        forDate={forDate}
                        slots={slotsByDoctor[doctor.id]}
                        availability={availability}
                      />
                    }
                  />
                </div>
                <div className={styles.contentRight}>
                  <DoctorTimeSlotV2
                    key={`doctor_timeslot_${i}`}
                    searchDate={forDate}
                    doctorId={doctor.id}
                    selectedOffice={selectedOffice}
                    availability={availability}
                    onSelection={onSelection}
                    onSelectNextAvail={onSelectNextAvail}
                    selectedSlot={selectedSlot}
                  />
                </div>
              </div>
            </>
          );
        } else if (availability == null) {
          return (
            <div
              className={
                /*!visible
                  ? i < 4
                    ? styles.doctorTimeSlotContainer
                    : cx(styles.limitedAvailability, styles.divHidden)
                  : styles.limitedAvailability
                  */
                !visible && doctors.length > 4 && isAvailability > 0
                  ? cx(styles.limitedAvailability, styles.divHidden)
                  : styles.limitedAvailability
              }
            >
              <DoctorButtonV2
                key={`doctor_${i}`}
                doctor={doctor}
                selected={selectedDoctorId === doctor.id}
                selectedOffice={selectedOffice}
                loaded={
                  areFirstAvailableApptsByDefaultEnabled()
                    ? firstAvailabilityLoaded
                    : availability !== "loading"
                }
                availabilityInfo={
                  <AvailabilityForDoctorV2
                    key={`doctor_avail_${i}`}
                    doctor={doctor}
                    office={selectedOffice}
                    forDate={forDate}
                    slots={slotsByDoctor[doctor.id]}
                    availability={availability}
                  />
                }
              />
            </div>
          );
        }
      })}
    </div>
  );
};

export const DoctorSelectionPanelV2: React.FC<DoctorSelectionPanelProps> = ({
  doctors,
  selectedOffice,
  forDate,
  visible,
  onSelection,
  onSelectNextAvail,
  selectedSlot,
  weekCalendar,
  loadOnce,
  onSetLoadOnce,
}) => {
  return (
    <>
      {doctors.length > 0 ? (
        <DoctorSlots
          doctors={doctors}
          forDate={forDate}
          selectedOffice={selectedOffice}
          visible={visible}
          onSelection={onSelection}
          onSelectNextAvail={onSelectNextAvail}
          selectedSlot={selectedSlot}
          weekCalendar={weekCalendar}
          loadOnce={loadOnce}
          onSetLoadOnce={onSetLoadOnce}
        />
      ) : (
        <div>
          <div>
            <div className={styles.noAvailableDoctors}>
              <p>
                We’r sorry! No Doctors available for this office, we encourage
                you to select from other nearby offices or{" "}
                <a href={noop}>join the waitlist.</a>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
