import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./AppointmentFailure.module.scss";
import { RootState } from "app/store";
import { ButtonLinkExternal } from "components/Button";
import * as paths from "features/routing/paths";
import { selectAppointmentData } from "features/appointmentDataSlice";
import { resetAppointmentBooked } from "features/scheduling/appointmentCreationSlice";
import { TextLink } from "components/TextLink";
import { SadLayout } from "features/layout/SadLayout";
import { toTel } from "features/scheduling/utils/toTel";

export const AppointmentFailureV2: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const appointmentData = useSelector((state: RootState) =>
    selectAppointmentData(state)
  );
  const tempappointmentData = appointmentData;
  const office = tempappointmentData.office;
  const officeId = office.id;

  useEffect(() => {
    return () => {
      dispatch(resetAppointmentBooked());
    };
  }, [dispatch]);

  const cta = (
    <>
      <ButtonLinkExternal
        className={styles.button}
        href={toTel(office.formattedPhone)}
        size="large"
        text="Call the office"
      />
      <TextLink to={paths.appointmentBookingStart({ officeId })}>
        Return to home
      </TextLink>
    </>
  );

  return (
    <SadLayout
      headline="We're sorry!"
      message={
        <p className={styles.message}>
          We are unable to schedule your appointment at this time. Please call
          the office at{" "}
          <a href={toTel(office.formattedPhone)}>{office.formattedPhone}</a> to
          schedule your appointment.
        </p>
      }
      cta={cta}
    />
  );
};
