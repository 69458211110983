import axios from "axios";
import { CurrentAppointment } from "types/currentAppointment";
import type { DocumentType } from "features/checkIn/types";

export interface GetCurrentAppointmentResponse {
  date: string;
  start_time: string;
  end_time: string;
  office_id: string;
  doctor_id: string;
  doctor_first_name: string;
  doctor_last_name: string;
  doctor_headshot_url?: string;
  time_zone_name: string;
  google_calendar_url: string;
  apple_calendar_url: string;
  outlook_calendar_url: string;
  is_reschedulable: boolean;
  checkin: {
    about_you: "unstarted" | "finished";
    insurance: "unstarted" | "finished" | "skipped";
    prescriptions: "unstarted" | "finished";
    acknowledged_document_types: DocumentType[];
    checkin_demographics?: {
      race?: string;
      preferred_name?: string;
      preferred_pronoun?: string;
      accommodations?: string;
    };
  };
}

export const getCurrentAppointmentRequest =
  async (): Promise<CurrentAppointment | null> => {
    const { data, status } = await axios.get<GetCurrentAppointmentResponse>(
      `/api/appointments/current`
    );

    if (status === 204) return null;

    return {
      date: data.date,
      startTime: data.start_time,
      endTime: data.end_time,
      officeId: data.office_id,
      doctor: {
        id: data.doctor_id,
        firstName: data.doctor_first_name,
        lastName: data.doctor_last_name,
        headshotUrl: data.doctor_headshot_url,
      },
      timeZoneName: data.time_zone_name,
      calendarLinks: {
        googleUrl: data.google_calendar_url,
        appleUrl: data.apple_calendar_url,
        outlookUrl: data.outlook_calendar_url,
      },
      isReschedulable: data.is_reschedulable,
      checkin: {
        aboutYou: data.checkin.about_you, 
        insurance: data.checkin.insurance, 
        prescriptions: data.checkin.prescriptions,
        acknowledgedDocumentTypes: data.checkin.acknowledged_document_types,
        checkinDemographics: data.checkin.checkin_demographics && {
          race: data.checkin.checkin_demographics.race,
          preferredName: data.checkin.checkin_demographics.preferred_name,
          preferredPronoun: data.checkin.checkin_demographics.preferred_pronoun,
          accommodations: data.checkin.checkin_demographics.accommodations,
        },
      },
    };
  };
export const getLastAppointmentRequest =
  async (): Promise<CurrentAppointment | null> => {
    const { data, status } = await axios.get<GetCurrentAppointmentResponse>(
      `/api/appointments/last`
    );
    if (status === 204) return null;

    return {
      date: data.date,
      startTime: data.start_time,
      endTime: data.end_time,
      officeId: data.office_id,
      doctor: {
        id: data.doctor_id,
        firstName: data.doctor_first_name,
        lastName: data.doctor_last_name,
        headshotUrl: data.doctor_headshot_url,
      },
      timeZoneName: data.time_zone_name,
      calendarLinks: {
        googleUrl: data.google_calendar_url,
        appleUrl: data.apple_calendar_url,
        outlookUrl: data.outlook_calendar_url,
      },
      isReschedulable: data.is_reschedulable,
      checkin: {
        aboutYou: data.checkin.about_you,
        insurance: data.checkin.insurance,
        prescriptions: data.checkin.prescriptions,
        acknowledgedDocumentTypes: data.checkin.acknowledged_document_types,
        checkinDemographics: data.checkin.checkin_demographics && {
          race: data.checkin.checkin_demographics.race,
          preferredName: data.checkin.checkin_demographics.preferred_name,
          preferredPronoun: data.checkin.checkin_demographics.preferred_pronoun,
          accommodations: data.checkin.checkin_demographics.accommodations,
        },
      },
    };
  };
