import axios from "axios";
import { Order } from "types/order";

export interface GetCurrentPatientOrdersResponse {
  order_num: number;
  order_date: string;
  order_type_name: "eyeglasses" | "soft_contacts";
  display_status: string;
  display_byline: string;
  fulfillment_type: string;
  fulfillment_destination: string;
  fulfillment_destination_map_url: string;
  expect_date: string;
}

export const getCurrentPatientEcomOrdersRequest = async (
  patientId: string
): Promise<Order[]> => {
  const { data } = await axios.get<GetCurrentPatientOrdersResponse[]>(
    `/order_details/${patientId}`
  );

  const orders = [];
  for (let i = 0; i < data.orders.length; i++) {
    const order = data.orders[i];
    orders.push({
      orderNum: order.OrderNum,
      orderDate: order.OrderDate,
      orderTypeName: order.OrderType,
      displayStatus: order.DisplayStatus,
      displayByline: order.display_byline,
      fulfillmentType: order.fulfillmentType,
      fulfillmentDestination: order.FulfillmentDestination,
      fulfillmentDestinationMapUrl: order.FulfillmentDestinationMapUrl,
      expectDate: order.ExpectDate,
    });
  }

  /*
  data.orders.map((order) => ({
    orderNum: order.order_num,
    orderDate: order.order_date,
    orderTypeName: order.order_type_name,
    displayStatus: order.display_status,
    displayByline: order.display_byline,
    fulfillmentType: order.fulfillment_type,
    fulfillmentDestination: order.fulfillment_destination,
    fulfillmentDestinationMapUrl: order.fulfillment_destination_map_url,
    expectDate: order.expect_date,
  }));
  */

  return orders;
};
