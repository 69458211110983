import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CurrentPatientContext } from "./CurrentPatientContext";
import {
  selectSessionStatus,
  SessionStatus,
  setSessionStatus,
} from "features/patientDashboard/patientDashboardSlice";
import { useSnackbar } from "features/patientDashboard/hooks/useSnackbar";
import { useErrorToast } from "features/patientDashboard/hooks/useErrorToast";
import { Patient } from "types/patient";
import { getCurrentPatientRequest } from "api/getCurrentPatientRequest";
import { deletePatientSessionRequest } from "api/deletePatientSessionRequest";

interface HandleUnauthorizedOptions {
  onUnauthorized: () => void;
}

interface UseCurrentPatientReturn {
  sessionStatus: SessionStatus;
  fetchCurrentPatient: () => void;
  handleUnauthorized: (
    callback: () => Promise<void>,
    options?: HandleUnauthorizedOptions
  ) => Promise<void>;
  logout: () => Promise<void>;
  expireSession: (options?: ExpireSessionOptions) => Promise<void>;
  currentPatient: undefined | Partial<Patient>;
}

interface ExpireSessionOptions {
  requestSessionDeletion?: boolean;
}

export const useCurrentPatient = (): UseCurrentPatientReturn => {
  const dispatch = useDispatch();
  const sessionStatus = useSelector(selectSessionStatus);
  const context = useContext(CurrentPatientContext);
  if (context === undefined) {
    throw new Error(
      "useCurrentPatient must be used within a CurrentPatientProvider"
    );
  }
  const { showErrorToast } = useErrorToast();
  const { currentPatient, setCurrentPatient } = context;
  const { displayMessage } = useSnackbar();

  const expireSession = React.useCallback(
    async (options: ExpireSessionOptions = {}) => {
      const { requestSessionDeletion } = {
        requestSessionDeletion: false,
        ...options,
      };

      try {
        if (requestSessionDeletion) {
          // This is optional because expireSession is often called when the session is known to already be expired
          await deletePatientSessionRequest();
        }
        const nextSessionStatus =
          sessionStatus === "logged-in" ? "expired" : "logged-out";
        dispatch(setSessionStatus(nextSessionStatus));
      } catch {
        showErrorToast();
      }
    },
    [dispatch, sessionStatus, showErrorToast]
  );

  const logout = React.useCallback(async () => {
    try {
      await deletePatientSessionRequest();
      dispatch(setSessionStatus("logged-out"));
      displayMessage({
        icon: "checkmark",
        text: "You have successfully logged out.",
      });
    } catch {
      showErrorToast();
    }
  }, [dispatch, displayMessage, showErrorToast]);

  // Checks to see if the user is logged in and updates the state accordingly
  const fetchCurrentPatient = React.useCallback(async () => {
    try {
      const patient = await getCurrentPatientRequest();
      setCurrentPatient(patient);
      dispatch(setSessionStatus("logged-in"));
    } catch (e) {
      expireSession();
    }
  }, [setCurrentPatient, dispatch, expireSession]);

  // a wrapper that automates handling unauthorized errors
  const handleUnauthorized = React.useCallback(
    async (
      callback: () => Promise<void>,
      options?: HandleUnauthorizedOptions
    ) => {
      try {
        await callback();
      } catch (err) {
        if (err.code === "403" || !!err.message?.match(/403/)) {
          (options?.onUnauthorized || expireSession)();
        } else {
          showErrorToast();
        }
      }
    },
    [showErrorToast, expireSession]
  );

  return {
    sessionStatus,
    fetchCurrentPatient,
    logout,
    expireSession,
    handleUnauthorized,
    currentPatient,
  };
};
