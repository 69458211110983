import React, { useEffect } from "react";
import dayjs from "dayjs";
import { Redirect } from "react-router-dom";
import styles from "./PatientDashboard.module.scss";
import { useCurrentPatient } from "./hooks/useCurrentPatient";
import { UpcomingAppointment } from "./UpcomingAppointment";
import { ErrorToast } from "./ErrorToast";
import { EyeHealthChecklist } from "./EyeHealthChecklist";
import { RecentPurchases } from "./RecentPurchases";
import { MyAccount } from "./MyAccount";
import { Layout } from "features/layout/Layout";
import { Navbar } from "components/Navbar";
import { portalLogin } from "features/routing/paths";

const getGreeting = () => {
  const hour = dayjs().hour();

  if (hour < 12) {
    return "Morning";
  } else if (hour < 17) {
    return "Afternoon";
  } else {
    return "Evening";
  }
};

declare let getCLXUrl: string;
export const PatientDashboard: React.FC = () => {
  const { sessionStatus, currentPatient, fetchCurrentPatient } =
    useCurrentPatient();

  // Check to see if the user is logged in when dashboard first mounts.
  useEffect(() => {
    if (sessionStatus === "uninitialized") {
      fetchCurrentPatient();
    }
  }, [fetchCurrentPatient, sessionStatus]);

  if (sessionStatus === "logged-out" || sessionStatus === "expired") {
    return <Redirect to={portalLogin()} />;
  }

  return (
    <Layout
      navbar={
        <>
          <Navbar
            office={currentPatient?.homeOffice}
            className={styles.myAccount}
          >
            <MyAccount />
          </Navbar>
        </>
      }
    >
      <ErrorToast />
      <div className={styles.container}>
        <div className={styles.patientGreeting}>
          Good {getGreeting()},
          <h1 id="chk-patientName" className={styles.patientName}>
            {currentPatient?.firstName} {currentPatient?.lastName}
          </h1>
        </div>
        <div className={styles.columns}>
          <UpcomingAppointment />
          <div>
            <EyeHealthChecklist />
            <div className={styles.ctas}>
              Need access to your prescriptions?{" "}
              <a
                href={getCLXUrl}
                className={styles.inlineLink}
                target="_blank"
                rel="noreferrer"
              >
                View&nbsp;prescriptions
              </a>
            </div>
          </div>
        </div>
        <div className={styles.recentPurchasesContainer}>
          {currentPatient && <RecentPurchases patient={currentPatient} />}
        </div>
      </div>
    </Layout>
  );
};
