import React from "react";
import cx from "classnames";
import ContentLoader from "react-content-loader";
import { noop } from "lodash";
import styles from "./DoctorButton.module.scss";
import { Doctor } from "types/doctor";
import { Office } from "types/office";
import { DoctorAvatar } from "components/DoctorAvatar";
import { doctorFullNameVEH } from "utils/doctorFullName";

interface DoctorButtonProps {
  selected?: boolean;
  doctor: Doctor;
  onClick?: () => void;
  availabilityInfo: React.ReactNode;
  loaded: boolean;
  isMobileView?: boolean;
  selectedOffice: Office;
}

export const DoctorButton: React.FC<DoctorButtonProps> = ({
  doctor,
  onClick,
  selected,
  availabilityInfo,
  loaded,
  isMobileView,
  selectedOffice,
}) => {
  const doctorName = loaded ? (
    doctorFullNameVEH(doctor, selectedOffice.namechangeVEH)
  ) : (
    <ContentLoader
      speed={2}
      width={100}
      height={19}
      viewBox="0 0 100 18"
      backgroundColor="var(--gray-02)"
      foregroundColor="var(--gray-04)"
    >
      <rect x="0" y="8" rx="4" ry="4" width="100" height="8" />
    </ContentLoader>
  );
  if (isMobileView) {
    return (
      <div>
        <div className={styles.profileMobile}>
          <div
            className={
              selectedOffice &&
              selectedOffice.namechangeVEH &&
              doctor.offersVEH &&
              doctor.headshotUrl &&
              doctor.firstName === "Open Network"
                ? cx(styles.docImgVEHNameChange, { [styles.avatar]: loaded })
                : cx(styles.docImg, { [styles.avatar]: loaded })
            }
          >
            <DoctorAvatar
              doctor={doctor}
              size="xsmall"
              loaded={loaded}
              selectedOffice={selectedOffice}
            />
          </div>
          <div className={styles.doctorInfo}>
            <div className={styles.name}>{doctorName}</div>
            <div className={styles.availabilityInfo}>{availabilityInfo}</div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <button
      onClick={loaded ? onClick : noop}
      className={
        selectedOffice &&
        selectedOffice.namechangeVEH &&
        doctor.offersVEH &&
        doctor.firstName === "Open Network"
          ? cx(styles.VEHdoctor, styles.doctor, { [styles.selected]: selected })
          : cx(styles.doctor, { [styles.selected]: selected })
      }
    >
      <div className={styles.indicator} />
      <div className={styles.profile}>
        <div
          className={
            selectedOffice &&
            selectedOffice.namechangeVEH &&
            doctor.offersVEH &&
            doctor.headshotUrl &&
            doctor.firstName === "Open Network"
              ? ""
              : cx({ [styles.avatar]: loaded })
          }
        >
          <DoctorAvatar
            doctor={doctor}
            selectedOffice={selectedOffice}
            size="xsmall"
            loaded={loaded}
          />
        </div>
        <div className={styles.name}>{doctorName}</div>
      </div>
      <div className={styles.availability}>{availabilityInfo}</div>
    </button>
  );
};
