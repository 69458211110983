import React from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import styles from "./NearbyDoctorAvailabilitySection.module.scss";
import * as paths from "features/routing/paths";
import { DoctorAvatarStack } from "components/DoctorAvatarStack";
import { ExamType } from "types/examType";
import { DateString } from "types/dateString";
import { Doctor } from "types/doctor";
import { Office } from "types/office";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { useSchedulerTracking } from "hooks/useSchedulerTracking";

interface DateLinkProps {
  office: Office;
  date?: DateString;
  examType: ExamType;
  patientReturnStatus: PatientReturnStatus;
  onClick?: React.MouseEventHandler;
  version?: number;
}

export const DateLink: React.FC<DateLinkProps> = ({
  office,
  date,
  examType,
  patientReturnStatus,
  onClick,
}) => {
  const text = date ? dayjs(date).format("ddd., MMM. D") : "Available Today";
  const officePath = paths.appointmentsAvailableAppointments({
    officeId: office.id,
    examType,
    patientReturnStatus,
    query: { date },
  });
  return (
    <Link to={officePath} className={styles.date} onClick={onClick}>
      {text}
    </Link>
  );
};

interface NearbyDoctorAvailabilitySectionProps {
  office: Office;
  doctors: Doctor[];
  date?: DateString;
  examType: ExamType;
  patientReturnStatus: PatientReturnStatus;
  selectedDoctorId?: string;
  version?: number;
}

export const NearbyDoctorAvailabilitySection: React.FC<
  NearbyDoctorAvailabilitySectionProps
> = ({
  office,
  doctors,
  date,
  examType,
  patientReturnStatus,
  selectedDoctorId,
  version,
}) => {
  const count = doctors.length;
  const { trackSchedulerEvent } = useSchedulerTracking(selectedDoctorId);

  if (count === 0) {
    return null;
  }

  const doctorsLabel = count === 1 ? "1 Doctor " : `${count} Doctors `;
  const datelabel = date ? "available starting " : null;

  return (
    <section className={!version ? styles.section : styles.sectionV2}>
      {!version && (
        <DoctorAvatarStack
          doctors={doctors.slice(0, 10)}
          callFrom={"nearby"}
          office={office}
        />
      )}
      <span className={styles.label}>
        {doctorsLabel}
        {datelabel}
        <DateLink
          office={office}
          date={date}
          examType={examType}
          patientReturnStatus={patientReturnStatus}
          onClick={() => trackSchedulerEvent("browse-nearby-offices")}
        />
      </span>
    </section>
  );
};
