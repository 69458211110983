import React from "react";
import cx from "classnames";
import dayjs from "dayjs";
import styles from "./DoctorCard.module.scss";
import { Doctor } from "types/doctor";
import { Slot } from "types/slot";
import { DoctorAvatar } from "components/DoctorAvatar";
import { doctorFullName } from "utils/doctorFullName";
import { parseStartTime } from "features/appointmentTime";
import { Office } from "types/office";

interface DoctorCardProps {
  doctor: Doctor;
  selectedOffice: Office;
  slot: Slot;
  isEditForm: false;
  onClick: () => void;
}

export const DoctorCard: React.FC<DoctorCardProps> = ({
  doctor,
  selectedOffice,
  slot,
  isEditForm,
  onClick,
}) => {
  const formattedTime = parseStartTime(slot).format("h:mm A");
  const vehThreeheadshot =
    doctor.offersVEH && doctor.headshotUrl
      ? doctor.headshotUrl.includes("OD_Group_Final_3.png")
      : false;
  return (
    <>
      <div
        className={
          !isEditForm ? styles.container : cx(styles.container, styles.moveTop)
        }
      >
        <div className={styles.headerSection}>
          <div className={styles.heading}>Selected Doctor and Time</div>
          <div
            className={styles.action}
            onClick={() => onClick()}
            aria-hidden="true"
          >
            Change
          </div>
        </div>
        <div className={styles.contentSection}>
          <div
            className={
              vehThreeheadshot
                ? cx(styles.contentLeft, styles.vehcontentLeft)
                : styles.contentLeft
            }
          >
            <DoctorAvatar
              doctor={doctor}
              size="small"
              selectedOffice={selectedOffice}
            />
          </div>
          <div
            className={
              vehThreeheadshot
                ? cx(styles.contentRight, styles.vehcontentRight)
                : styles.contentRight
            }
          >
            <div className={styles.doctorName}>{doctorFullName(doctor)}</div>
            <div className={styles.timeSlot}>
              {dayjs(slot.date).format("ddd, MMM D, YYYY")} at {formattedTime}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
