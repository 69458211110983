import React from "react";
import styles from "./RescheduleModalContent.module.scss";
import * as paths from "features/routing/paths";
import { Button, ButtonLinkInternal, ButtonRole } from "components/Button";

interface RescheduleModalContentProps {
  officeId: string;
  setMightBeReschedulingFn: (mightBeRescheduling: boolean) => void;
}

export const rescheduleModalAria = {
  labelledby: "reschedule-modal-content-label",
};

export const RescheduleModalContent: React.FC<RescheduleModalContentProps> = ({
  officeId,
  setMightBeReschedulingFn,
}) => {
  return (
    <>
      <h1 className={styles.header}>Rescheduling Your Appointment</h1>
      <p id={rescheduleModalAria.labelledby} className={styles.message}>
        Your current appointment will be reserved until a new appointment is
        scheduled.
      </p>
      <div className={styles.buttons}>
        <Button
          text="Back"
          role={ButtonRole.Ghost}
          size="large"
          onClick={() => {
            setMightBeReschedulingFn(false);
          }}
          className={styles.button}
        />
        <ButtonLinkInternal
          to={paths.appointmentBookingStart({
            officeId,
          })}
          text="Continue"
          size="large"
          className={styles.button}
        />
      </div>
    </>
  );
};
