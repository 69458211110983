import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./WaitlistV2.module.scss";
import { Form, FormData, waitlistFormAria } from "./FormV2";
import { ThankYou, waitlistThankYouAria } from "./ThankYou";
import { Modal } from "components/Modal";
import { useTrackEvent } from "hooks/useTrackEvent";
import { TextButton } from "components/TextButton";
import timelyIcon from "images/timely-icon.svg";
import { ExamType } from "types/examType";
import { Office } from "types/office";
import { PatientReturnStatus } from "types/patientReturnStatus";
import {
  CreatedStatus,
  joinWaitList,
  waitListLoadingState,
  waitListCreateStatusState,
} from "features/scheduling/waitListSlice";
import { RootState } from "app/store";

enum ComponentState {
  Closed,
  Open,
  Submitted,
}

interface WaitListProps {
  office: Office;
  className?: string;
  selectedDoctorId?: string;
}

interface WaitListRouterProps {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  officeId: string;
  examType: ExamType;
  patientReturnStatus: PatientReturnStatus;
}

export const WaitlistV2: React.FC<WaitListProps> = ({
  office,
  className,
  selectedDoctorId,
}) => {
  const { trackEvent } = useTrackEvent();
  const dispatch = useDispatch();
  const [componentState, setComponentState] = useState(ComponentState.Closed);
  const { officeId } = useParams<WaitListRouterProps>();
  const examType = ExamType.GlassesAndContacts;
  const patientReturnStatus = PatientReturnStatus.Returning;
  const isLoading = useSelector((state: RootState) =>
    waitListLoadingState(state)
  );
  const createdStatus = useSelector((state: RootState) =>
    waitListCreateStatusState(state)
  );

  const officeName = office.displayName;
  let officeAddress = "";
  if (office.address2) {
    officeAddress =
      office.address1 +
      ", " +
      office.address2 +
      ", " +
      office.city +
      ", " +
      office.stateAbbr +
      ", " +
      office.formattedZipCode;
  } else {
    officeAddress =
      office.address1 +
      ", " +
      office.city +
      ", " +
      office.stateAbbr +
      ", " +
      office.formattedZipCode;
  }

  const onOpen = () => {
    trackEvent("open-waitlist");
    setComponentState(ComponentState.Open);
  };

  const onClose = () => setComponentState(ComponentState.Closed);
  const createdWaitList = createdStatus === CreatedStatus.Created;

  const onSubmit = ({
    firstName,
    lastName,
    phoneNumber,
    email,
    desiredDayOfWeek,
    desiredTimeOfDay = "",
    smsOptInNotices = 0,
    smsOptInPromos = 0,
  }: FormData) => {
    dispatch(
      joinWaitList({
        firstName,
        lastName,
        phoneNumber,
        email,
        acuity_office_id: officeId,
        examType,
        patientReturnStatus,
        desiredDayOfWeek,
        desiredTimeOfDay,
        officeName,
        officeAddress,
        smsOptInNotices,
        smsOptInPromos,
      })
    );
  };

  useEffect(() => {
    if (createdWaitList && componentState === ComponentState.Open) {
      trackEvent("submit-waitlist");
      setComponentState(ComponentState.Submitted);
    }
  }, [createdWaitList, trackEvent, componentState]);

  if (createdWaitList && componentState === ComponentState.Closed) {
    return null;
  }

  return (
    <>
      <div className={className}>
        <div className={styles.prompt}>
          <div className={styles.hideBackground}>
            <span className={styles.promptHeadline}>
              <img src={timelyIcon} alt="" /> Can&rsquo;t find the perfect time?
            </span>
            <TextButton onClick={onOpen}>Join our waitlist</TextButton>
          </div>
        </div>
      </div>
      <Modal
        isOpen={componentState === ComponentState.Open}
        onRequestClose={onClose}
        aria={waitlistFormAria}
        closeOnOverlayClick={false}
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
        customCloseIcon={true}
      >
        <>
          {!createdWaitList && createdStatus !== CreatedStatus.isPending && (
            <div className={styles.somethingWentWrong}>
              Something went wrong !!
            </div>
          )}
          <Form
            onSubmit={onSubmit}
            isLoading={isLoading}
            office={office}
            selectedDoctorId={selectedDoctorId}
          />
        </>
      </Modal>
      <Modal
        isOpen={componentState === ComponentState.Submitted}
        onRequestClose={onClose}
        aria={waitlistThankYouAria}
        closeOnOverlayClick={false}
      >
        <ThankYou onClose={onClose} />
      </Modal>
    </>
  );
};
